<template>
  <div>
    <b-modal
      id="subscriber-modal"
      ref="subscriber-modal"
      size="lg"
      hide-header
      hide-footer
      hide-header-close
      no-close-on-backdrop
      body-class="p-0"
      modal-class="right"
      @hidden="onModelHide"
    >
      <b-container class="px-0">
        <b-row class="mx-0">
          <a
            :style="{ width: '40px', height: '40px', zIndex: 9 }"
            class="cursor-pointer d-inline-block position-absolute text-primary text-center line-height-40 heading-5 text-decoration-none modal-close-position-demo-form"
            @click="$refs['subscriber-modal'].hide()"
          >
            <i class="cyicon-close"></i>
          </a>
          <b-col cols="12" class="p-0 cy-bg-lighter-blue">
            <b-row class="m-0 justify-content-start align-items-center mt-3">
              <b-col cols="6" class="px-md-5 pb-md-0 pb-4">
                <h2
                  class="mb-0 cy-text-nile-blue mx-auto pt-3 cy-heading-2 font-weight-bolder"
                >
                  Get the
                  {{ fromFooter ? 'Cyware Newsletter' : $route.meta.title }}
                  delivered to your email!
                </h2>
              </b-col>
              <b-col cols="6" class="text-center">
                <b-img-lazy
                  :src="
                    require(`~/static${
                      $route.meta.headerImg
                        ? $route.meta.headerImg
                        : '/blog/blog.png'
                    }`)
                  "
                  :alt="$route.meta.title || 'Cyware Newsletter'"
                  :title="$route.meta.title || 'Cyware Newsletter'"
                  :class="
                    $route.meta.slug === 'research-and-analysis'
                      ? ''
                      : 'w-50 h-75'
                  "
                  fluid
                >
                </b-img-lazy>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="px-0">
            <div
              v-if="isResponse"
              class="p-3 h-100 d-flex justify-content-center align-items-center my-5"
            >
              <div class="text-center py-4">
                <p>
                  <span
                    class="cyicon-check-circle cy-heading-1 cy-text-primary"
                  ></span>
                </p>
                <h3 class="cy-text-primary cy-heading-3 cy-word-break">
                  Thank you for your subscription.<br />
                  You will be receiving emails on
                  <strong>{{ subscriberModel.email_id }}</strong>
                </h3>
                <div class="w-100 mt-3 cy-heading-4">
                  <a href="/dashboard/subscriptions">
                    Manage My Subscriptions
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="p-5">
              <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(onSubmit)">
                  <b-form-group
                    v-if="
                      subscriptionList.filter((x) => x.is_subscribed).length > 0
                    "
                    label="You are about to Subscribe"
                  >
                    <b-form-checkbox-group
                      id="curentsubscribedList"
                      v-model="subscriberModel.subscription"
                      class="d-flex m-r-0-last flex-wrap mb-2 mt-2"
                      name="curentsubscribedList"
                    >
                      <b-form-checkbox
                        v-for="item in subscriptionList.filter(
                          (x) => x.is_subscribed === true
                        )"
                        :key="item.slug"
                        :value="item"
                        :title="item.title"
                        class="mt-2 mt-md-0 mr-3"
                        @click.native="deSelectSubscription(item)"
                      >
                        <div class="demo-product-icon mb-3">
                          <div class="d-flex align-items-center">
                            <div>
                              {{ item.title }}
                              <span class="pl-2 cy-plus-icon">+</span>
                              <i class="cyicon-check pl-1"></i>
                            </div>
                          </div>
                        </div>
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <!-- <b-form-invalid-feedback
                      v-if="validateList() <= 0"
                      :force-show="true"
                    >
                      Please Select atleast one Subscription to proceed
                    </b-form-invalid-feedback> -->
                  </b-form-group>

                  <b-form-group
                    v-if="
                      subscriptionList.filter((x) => x.is_subscribed === false)
                        .length > 0
                    "
                    label="You can also subscribe to our other resources and stay updated!"
                  >
                    <button
                      v-for="item in subscriptionList.filter(
                        (x) => x.is_subscribed === false
                      )"
                      :key="item.slug"
                      class="demo-product-icon mb-2 mt-2 mr-3 cursor-pointer"
                      @click="selectCurrentSubscription(item.slug)"
                    >
                      <div class="d-flex align-items-center">
                        <div>
                          {{ item.title }}
                          <span class="pl-2 cy-plus-icon">+</span>
                          <i class="cyicon-check pl-1"></i>
                        </div>
                      </div>
                    </button>
                    <!-- <b-form-checkbox-group
                      v-if="subscriptionList.length > 0"
                      id="subscriptionList"
                      class="d-flex m-r-0-last flex-wrap mb-2 mt-2"
                      name="subscriptionList"
                      @change="selectCurrentSubscription"
                    >
                      <b-form-checkbox
                        v-for="item in subscriptionList.filter(
                          (x) => x.is_subscribed === false
                        )"
                        :key="item.slug"
                        :value="item"
                        :title="item.title"
                        class="mt-2 mt-md-0 mr-3"
                      >
                        <div class="demo-product-icon mb-3">
                          <div class="d-flex align-items-center">
                            <div>
                              {{ item.title }}
                              <span class="pl-2 cy-plus-icon">+</span>
                              <i class="cyicon-check pl-1"></i>
                            </div>
                          </div>
                        </div>
                      </b-form-checkbox>
                    </b-form-checkbox-group> -->
                    <!-- <b-form-invalid-feedback
                      v-if="validateList() <= 0"
                      :force-show="true"
                    >
                      Please Select atleast one Subscription to proceed
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                  <b-row v-if="!isAuthenticated" class="mb-4">
                    <b-col md="6" xs="12">
                      <b-form-group label="Your email address">
                        <cy-input-map
                          id="email_1"
                          v-model="subscriberModel.email_id"
                          :maxlength="100"
                          :rules="{
                            required: true,
                            email: true
                          }"
                          type="email"
                          name="email"
                          vid="email"
                          label="Your email address"
                          placeholder="yourname@company.com"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- <div
                    v-if="
                      !isAuthenticated ||
                      (userDetails &&
                        userDetails.subscription.filter(
                          (x) => x.is_subscribed === false
                        ).length > 0)
                    "
                    class="d-flex my-3 align-items-center flex-wrap"
                  >
                    <b-button
                      type="submit"
                      class="cy-btn cy-btn--primary cy-btn--extra-padding"
                    >
                      <span
                        class="d-inline-block cy-font-weight-800 text-uppercase"
                        >{{ loading ? 'Loading...' : 'Subscribe' }}</span
                      >
                    </b-button>
                  </div>
                  <hr /> -->
                  <div class="mt-3">
                    <div class="d-block align-middle mr-3 mb-3">
                      <vue-recaptcha
                        ref="recaptcha"
                        size="invisible"
                        badge="bottomright"
                        sitekey="6Ler-iAUAAAAAJsAcOYyqEsNj_Wjpfe7i7x0rNUM"
                        @verify="onCaptchaVerified"
                        @expired="onCaptchaExpired"
                      />
                      <p v-if="showCaptchaError" class="text-danger small">
                        Please confirm that you are not a robot.
                      </p>
                    </div>

                    <div
                      v-if="
                        !isAuthenticated ||
                        (userDetails &&
                          userDetails.subscription.filter(
                            (x) => x.is_subscribed === false
                          ).length > 0)
                      "
                      class="d-flex my-3 align-items-center flex-wrap"
                    >
                      <b-btn
                        type="submit"
                        class="cy-btn contact-us-submit cy-btn--primary cy-btn--extra-padding"
                      >
                        <span
                          class="d-inline-block cy-font-weight-800 text-uppercase"
                          >{{ loading ? 'Loading...' : 'Subscribe' }}</span
                        >
                      </b-btn>
                    </div>
                  </div>
                  <hr />

                  <!-- <div>
                    <p class="cy-text-light-grey">
                      Note: To manage your subscription options, click on the My
                      Subscription option when logged in to make any changes.
                    </p>
                  </div> -->
                </b-form>
              </ValidationObserver>
              <h2
                v-if="getSubscribedList()"
                class="cy-heading-4 mb-3 mt-3 cy-line-height-25"
              >
                You are currently subscribed to
                <strong>{{ getSubscribedList() }} </strong>
              </h2>
              <div class="w-100 mt-2 cy-heading-4 cy-font-weight-600">
                <a href="/dashboard/subscriptions"> Manage My Subscriptions </a>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import RecaptchaMixin from '@/plugins/RecaptchaMixin'
import { EventBus } from '@/plugins/event-bus.js'
export default {
  components: { ValidationObserver },
  mixins: [RecaptchaMixin],
  props: {
    fromFooter: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      isResponse: false,
      captchaVerified: false,
      showCaptchaError: false,
      captcha: true,
      loading: false,
      recaptchaKey: null,
      subscriberModel: {
        email_id: '',
        subscription: []
      },
      subscriptionList: [],
      currentSubscription: []
    }
  },
  // computed: {
  //   subscriptionList() {
  //     // if (
  //     //   this.isAuthenticated &&
  //     //   this.userDetails &&
  //     //   Object.keys(this.userDetails).length > 0
  //     // ) {
  //     //   return this.userDetails.subscription
  //     // } else {
  //     //   return this.$store.state.list.SUBSCRIPTION_LIST
  //     // }
  //     return this.$store.state.list.SUBSCRIPTION_LIST
  //   }
  // },
  mounted() {
    this.getSubscriptions()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'subscriber-modal') {
        if (
          this.isAuthenticated &&
          this.userDetails &&
          Object.keys(this.userDetails).length > 0
        ) {
          this.computedSubscriptionList()
        }
        this.getSubscribedList()
        if (this.$route.meta.slug) {
          this.selectCurrentSubscription(this.$route.meta.slug)
        }
      }
    })

    // this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
    //   if (modalId === 'subscriber-modal') {
    //     this.getSubscriptions()
    //   }
    // })
  },
  methods: {
    validateList() {
      return this.subscriberModel.subscription.length
    },
    getSubscriptions() {
      this.$axios
        .$get(
          `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/subscription/`
        )
        .then((data) => {
          if (
            this.isAuthenticated &&
            this.userDetails &&
            Object.keys(this.userDetails).length > 0
          ) {
            this.computedSubscriptionList()
          } else {
            for (const i of data) {
              i.is_subscribed = false
            }
            this.subscriptionList = data
          }
        })
    },
    computedSubscriptionList() {
      this.subscriptionList = JSON.parse(
        JSON.stringify(
          this.userDetails.subscription.filter((x) => !x.is_subscribed)
        )
      )
    },
    getSubscribedList() {
      if (
        this.isAuthenticated &&
        this.userDetails &&
        Object.keys(this.userDetails).length > 0
      ) {
        const userSubscribed = []
        for (const item of this.userDetails.subscription) {
          if (item.is_subscribed) {
            userSubscribed.push(item.title)
          }
        }
        return userSubscribed.join(', ')
      } else {
        return false
      }
    },
    selectCurrentSubscription(slug) {
      const currentSubscriptionIndex = this.subscriptionList.findIndex(
        (x) => x.slug === slug
      )
      if (currentSubscriptionIndex >= 0) {
        const tempItem = this.subscriptionList[currentSubscriptionIndex]
        tempItem.is_subscribed = true
        this.$set(this.subscriberModel, 'subscription', [
          ...this.subscriberModel.subscription,
          tempItem
        ])
      }
    },
    deSelectSubscription(item) {
      item.is_subscribed = false
      const currentIndex = this.subscriberModel.subscription.findIndex(
        (x) => x.slug === item.slug
      )
      if (currentIndex >= 0) {
        // const tempItem = this.this.subscriberModel.subscription[currentIndex]
        this.subscriberModel.subscription.splice(currentIndex, 1)
      }
    },
    disableItem(item) {
      if (
        this.isAuthenticated &&
        this.userDetails &&
        Object.keys(this.userDetails).length > 0 &&
        item.is_subscribed
      ) {
        return true
      } else {
        return false
      }
    },
    executeCaptcha() {
      this.$refs.recaptcha.execute()
    },
    onSubmit() {
      if (!this.captchaVerified) {
        this.showCaptchaError = true
        this.executeCaptcha()
      } else {
        if (this.validateList() <= 0) {
          return false
        }
        if (
          this.isAuthenticated &&
          this.userDetails &&
          Object.keys(this.userDetails).length > 0
        ) {
          this.subscriberModel.email_id = this.userDetails.email
        }
        const subscriberModel = {
          ...this.subscriberModel,
          'g-recaptcha-response': this.recaptchaKey
        }
        this.loading = true
        this.$axios
          .$post(
            `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/subscription/`,
            subscriberModel
          )
          .then((data) => {
            if (data) {
              this.isResponse = true
              if (this.isAuthenticated) {
                this.getUserProfile()
              }
            }
            this.gtag_report_conversion()
            this.loading = false
          })
          .catch((error) => {
            if (error) {
              this.loading = false
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                window.location = '/login?source=enterprise'
              }
            }
          })
      }
    },
    gtag_report_conversion(url) {
      const callback = function () {
        if (typeof url !== 'undefined') {
          window.location = url
        }
      }
      // eslint-disable-next-line no-undef
      gtag('event', 'conversion', {
        send_to: 'AW-712433333/jREMCOK5uP4BELW929MC',
        event_callback: callback
      })
      return false
    },
    async getUserProfile() {
      await this.$axios
        .$get(
          `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/user-profile-details`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: this.$cookies.get('auth._token.local')
            }
          }
        )
        .then((data) => {
          this.$store.commit('makeData', {
            reference: 'USER_PROFILE',
            data
          })
        })
        .catch((error) => {
          if (error) {
            this.loading = false
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              this.$store.commit('resetAuthentication')
            }
          }
        })
    },
    onModelHide() {
      this.subscriberModel = {
        subscription: [],
        email_id: ''
      }
      this.getSubscriptions()
      this.isResponse = false
      this.loading = false
      EventBus.$emit('refreshSubscribeStatus')
      this.$emit('fromFooter')
      // this.fromFooter = false
      // this.$emit('onClose')
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.recaptchaKey = null
      this.captchaVerified = false
      this.showCaptchaError = true
    },
    onCaptchaVerified(token) {
      this.recaptchaKey = token
      this.captchaVerified = true
      this.showCaptchaError = false
      this.onSubmit()
    }
  }
}
</script>
