import VueRecaptcha from 'vue-recaptcha'
const SITE_KEY = '6Lc600cmAAAAAHNqHkg8wM4lV2aMNr6KlR3yAWRB'
export default {
  components: { VueRecaptcha },
  data() {
    return { sitekey: SITE_KEY }
  },
  head() {
    return {
      script: [
        {
          src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit',
          defer: true,
          async: true
        }
      ]
    }
  }
}
