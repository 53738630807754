var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "subscriber-modal",
    attrs: {
      "id": "subscriber-modal",
      "size": "lg",
      "hide-header": "",
      "hide-footer": "",
      "hide-header-close": "",
      "no-close-on-backdrop": "",
      "body-class": "p-0",
      "modal-class": "right"
    },
    on: {
      "hidden": _vm.onModelHide
    }
  }, [_c('b-container', {
    staticClass: "px-0"
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('a', {
    staticClass: "cursor-pointer d-inline-block position-absolute text-primary text-center line-height-40 heading-5 text-decoration-none modal-close-position-demo-form",
    style: {
      width: '40px',
      height: '40px',
      zIndex: 9
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['subscriber-modal'].hide();
      }
    }
  }, [_c('i', {
    staticClass: "cyicon-close"
  })]), _vm._v(" "), _c('b-col', {
    staticClass: "p-0 cy-bg-lighter-blue",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "m-0 justify-content-start align-items-center mt-3"
  }, [_c('b-col', {
    staticClass: "px-md-5 pb-md-0 pb-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('h2', {
    staticClass: "mb-0 cy-text-nile-blue mx-auto pt-3 cy-heading-2 font-weight-bolder"
  }, [_vm._v("\n                Get the\n                " + _vm._s(_vm.fromFooter ? 'Cyware Newsletter' : _vm.$route.meta.title) + "\n                delivered to your email!\n              ")])]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('b-img-lazy', {
    class: _vm.$route.meta.slug === 'research-and-analysis' ? '' : 'w-50 h-75',
    attrs: {
      "src": require(`~/static${_vm.$route.meta.headerImg ? _vm.$route.meta.headerImg : '/blog/blog.png'}`),
      "alt": _vm.$route.meta.title || 'Cyware Newsletter',
      "title": _vm.$route.meta.title || 'Cyware Newsletter',
      "fluid": ""
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('b-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.isResponse ? _c('div', {
    staticClass: "p-3 h-100 d-flex justify-content-center align-items-center my-5"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('p', [_c('span', {
    staticClass: "cyicon-check-circle cy-heading-1 cy-text-primary"
  })]), _vm._v(" "), _c('h3', {
    staticClass: "cy-text-primary cy-heading-3 cy-word-break"
  }, [_vm._v("\n                Thank you for your subscription."), _c('br'), _vm._v("\n                You will be receiving emails on\n                "), _c('strong', [_vm._v(_vm._s(_vm.subscriberModel.email_id))])]), _vm._v(" "), _c('div', {
    staticClass: "w-100 mt-3 cy-heading-4"
  }, [_c('a', {
    attrs: {
      "href": "/dashboard/subscriptions"
    }
  }, [_vm._v("\n                  Manage My Subscriptions\n                ")])])])]) : _c('div', {
    staticClass: "p-5"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var passes = _ref.passes;
        return [_c('b-form', {
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return passes(_vm.onSubmit);
            }
          }
        }, [_vm.subscriptionList.filter(function (x) {
          return x.is_subscribed;
        }).length > 0 ? _c('b-form-group', {
          attrs: {
            "label": "You are about to Subscribe"
          }
        }, [_c('b-form-checkbox-group', {
          staticClass: "d-flex m-r-0-last flex-wrap mb-2 mt-2",
          attrs: {
            "id": "curentsubscribedList",
            "name": "curentsubscribedList"
          },
          model: {
            value: _vm.subscriberModel.subscription,
            callback: function ($$v) {
              _vm.$set(_vm.subscriberModel, "subscription", $$v);
            },
            expression: "subscriberModel.subscription"
          }
        }, _vm._l(_vm.subscriptionList.filter(function (x) {
          return x.is_subscribed === true;
        }), function (item) {
          return _c('b-form-checkbox', {
            key: item.slug,
            staticClass: "mt-2 mt-md-0 mr-3",
            attrs: {
              "value": item,
              "title": item.title
            },
            nativeOn: {
              "click": function ($event) {
                return _vm.deSelectSubscription(item);
              }
            }
          }, [_c('div', {
            staticClass: "demo-product-icon mb-3"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', [_vm._v("\n                            " + _vm._s(item.title) + "\n                            "), _c('span', {
            staticClass: "pl-2 cy-plus-icon"
          }, [_vm._v("+")]), _vm._v(" "), _c('i', {
            staticClass: "cyicon-check pl-1"
          })])])])]);
        }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.subscriptionList.filter(function (x) {
          return x.is_subscribed === false;
        }).length > 0 ? _c('b-form-group', {
          attrs: {
            "label": "You can also subscribe to our other resources and stay updated!"
          }
        }, _vm._l(_vm.subscriptionList.filter(function (x) {
          return x.is_subscribed === false;
        }), function (item) {
          return _c('button', {
            key: item.slug,
            staticClass: "demo-product-icon mb-2 mt-2 mr-3 cursor-pointer",
            on: {
              "click": function ($event) {
                return _vm.selectCurrentSubscription(item.slug);
              }
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', [_vm._v("\n                        " + _vm._s(item.title) + "\n                        "), _c('span', {
            staticClass: "pl-2 cy-plus-icon"
          }, [_vm._v("+")]), _vm._v(" "), _c('i', {
            staticClass: "cyicon-check pl-1"
          })])])]);
        }), 0) : _vm._e(), _vm._v(" "), !_vm.isAuthenticated ? _c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "6",
            "xs": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Your email address"
          }
        }, [_c('cy-input-map', {
          attrs: {
            "id": "email_1",
            "maxlength": 100,
            "rules": {
              required: true,
              email: true
            },
            "type": "email",
            "name": "email",
            "vid": "email",
            "label": "Your email address",
            "placeholder": "yourname@company.com"
          },
          model: {
            value: _vm.subscriberModel.email_id,
            callback: function ($$v) {
              _vm.$set(_vm.subscriberModel, "email_id", $$v);
            },
            expression: "subscriberModel.email_id"
          }
        })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "mt-3"
        }, [_c('div', {
          staticClass: "d-block align-middle mr-3 mb-3"
        }, [_c('vue-recaptcha', {
          ref: "recaptcha",
          attrs: {
            "size": "invisible",
            "badge": "bottomright",
            "sitekey": "6Ler-iAUAAAAAJsAcOYyqEsNj_Wjpfe7i7x0rNUM"
          },
          on: {
            "verify": _vm.onCaptchaVerified,
            "expired": _vm.onCaptchaExpired
          }
        }), _vm._v(" "), _vm.showCaptchaError ? _c('p', {
          staticClass: "text-danger small"
        }, [_vm._v("\n                      Please confirm that you are not a robot.\n                    ")]) : _vm._e()], 1), _vm._v(" "), !_vm.isAuthenticated || _vm.userDetails && _vm.userDetails.subscription.filter(function (x) {
          return x.is_subscribed === false;
        }).length > 0 ? _c('div', {
          staticClass: "d-flex my-3 align-items-center flex-wrap"
        }, [_c('b-btn', {
          staticClass: "cy-btn contact-us-submit cy-btn--primary cy-btn--extra-padding",
          attrs: {
            "type": "submit"
          }
        }, [_c('span', {
          staticClass: "d-inline-block cy-font-weight-800 text-uppercase"
        }, [_vm._v(_vm._s(_vm.loading ? 'Loading...' : 'Subscribe'))])])], 1) : _vm._e()]), _vm._v(" "), _c('hr')], 1)];
      }
    }])
  }), _vm._v(" "), _vm.getSubscribedList() ? _c('h2', {
    staticClass: "cy-heading-4 mb-3 mt-3 cy-line-height-25"
  }, [_vm._v("\n              You are currently subscribed to\n              "), _c('strong', [_vm._v(_vm._s(_vm.getSubscribedList()) + " ")])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "w-100 mt-2 cy-heading-4 cy-font-weight-600"
  }, [_c('a', {
    attrs: {
      "href": "/dashboard/subscriptions"
    }
  }, [_vm._v(" Manage My Subscriptions ")])])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }